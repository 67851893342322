/*Project Card*/
@use "../../App";

.card {
  @include App.flex($direction: column, $justify: space-between);
  width: 100%;
  height: auto;
  margin: 2vmin 8vmin;
  padding: 4vmin;
  background: rgba(20, 20, 20, 1);
  box-shadow: 0 2px 10px black;
  text-align: left;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;

  @media (min-width: 1024px) {
    @include App.flex($direction: column, $justify: space-between);
    width: 40vmin;
    margin: 2vmin;
    padding: 2vmin;
  }

  .card-header {
    padding: 1vmin;

    .card-title {
      @include App.flex($direction: row, $justify: space-between);
      margin: 0 0 2vmin 0;
      border-bottom: 1px solid App.$Light-Gray;

      h4 {
        color: App.$White;
        font-family: App.$Montserrat;
        font-size: 5vmin;
        padding: 0 0 0.1vmin 0vmin;

        @media (min-width: 1024px) {
          font-size: 4vmin;
          font-family: App.$Alumni;
        }
      }
    }

    .card-image {
      width: 100%;
      height: auto;
      object-fit: cover;
      padding: 2vmin 2vmin 2vmin 2vmin;

      @media (min-width: 1024px) {
        padding: 0;
        height: 20vmin;
      }
    }
  }

  .card-body {
    @include App.flex($direction: column, $justify: space-between);
    padding: 1vmin;

    p {
      @include App.p($size-s: 4.5vmin, $size-l: 2vmin);
      line-height: 1.3;
    }
  }

  .card-footer {
    @include App.flex($direction: column, $justify: center);
    padding: 3vmin 1vmin 1vmin 1vmin;

    @media (min-width: 1024px) {
      padding: 2vmin 1vmin 1vmin 1vmin;
    }

    p {
      @include App.p($color: App.$White, $size-s: 3.5vmin, $size-l: 1.7vmin);
    }
  }
}

.grow {
  height: 100%;
  transform: scale(100%);
  animation: grow 900ms;
}

.shrink {
  height: 0;
  transform: scale(0%);
  animation: shrink 300ms;
}

@keyframes grow {
  0% {
    transform: scale(0%);
  }
  100% {
    transform: scale(100%);
  }
}

@keyframes shrink {
  0% {
    transform: scale(100%);
  }
  100% {
    transform: scale(0%);
  }
}
