@use "../../App";

.featured-project {
  margin-bottom: 7rem;
  padding: 0 4vmin 0 4vmin;

  .featured-container {
    @include App.flex($direction: row, $justify: space-between);
    flex-wrap: wrap;

    @media (min-width: 1024px) {
      flex-wrap: nowrap;
    }

    .picture {
      padding: 2vmin;

      img {
        width: 100%;
        height: auto;
        border-radius: 5px;
        object-fit: cover;
        object-position: top;
        @media (min-width: 1024px) {
          width: 80vmin;
          height: 35vmin;
        }
      }
    }

    .featured-card {
      @include App.flex($direction: column, $justify: space-between);
      width: 100%;
      height: auto;
      margin: 2vmin 0vmin;
      padding: 4vmin;
      border-radius: 5px;

      @media (min-width: 1024px) {
        @include App.flex($direction: column, $justify: space-between);
        width: 150vmin;
        margin: 0vmin;
        padding: 1vmin 2vmin;
      }

      .featured-header {
        padding: 1vmin;

        .featured-pre {
          @include App.p($size-s: 4vmin, $size-l: 2.5vmin);
        }

        .featured-title {
          @include App.flex($direction: row, $justify: space-between);
          margin: 0 0 2vmin 0;
          border-bottom: 1px solid App.$Light-Gray;

          h4 {
            color: App.$White;
            font-family: App.$Montserrat;
            font-size: 5vmin;
            padding: 0 0 0.1vmin 0vmin;

            @media (min-width: 1024px) {
              font-size: 5vmin;
              font-family: App.$Alumni;
            }
          }
        }
      }

      .featured-body {
        @include App.flex($direction: column, $justify: space-between);
        padding: 1vmin;

        p {
          @include App.p($size-s: 4.5vmin, $size-l: 2vmin);
          line-height: 1.3;
        }
      }

      .featured-footer {
        @include App.flex($direction: column, $justify: center);
        padding: 3vmin 1vmin 1vmin 1vmin;

        @media (min-width: 1024px) {
          padding: 2vmin 1vmin 0vmin 1vmin;
        }

        p {
          @include App.p($color: App.$White, $size-s: 3.5vmin, $size-l: 2vmin);
        }
      }
    }
  }
}
