@use '../../App';

.hamburger-wrap {
    display: flex;
	@media (min-width: 1024px) {
		display: none;
	}
}

.nav-wrap {
    @include App.flex($direction: row, $justify: space-between, $align: center);
    height:70px;
    background: rgba(26, 0, 68, 0.4);
    position: fixed;
    transition: top 0.3s;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    box-shadow: 0 -15px 50px black;
    backdrop-filter: blur(20px);
    transition: box-shadow .5s, top .5s;

    .logo-container {
        width: 100%;
        height: auto;
        justify-content: flex-start;
        margin-right: auto;
    }
      
    img {
        margin-left: 20px;
        width: 34px;
        height: 36px;
        z-index: 2;

        @media ((min-width: 768px) and (max-width: 1023px)) {
            margin-top: 10px;
            width: 54px;
            height: 56px;
        }

        @media (min-width: 1024px) {
            margin-left: 3vmin;
            height: 40px;
            width: 38px;
        }
    }

    .nav-links{
        display: none;

        @media (min-width: 1024px) {
            display: flex;
        }
    
        li {
            list-style-type: none;
            padding: .2rem 1rem;
            border-left: 1px solid App.$Light-Purple;
        }


        li:last-of-type {
            border-right: 1px solid App.$Light-Purple;
        }
    
        a {
            text-decoration: none;
            color: App.$White;
            font-family: App.$Montserrat;
            font-size: 2vmin;
            z-index: 2;
        }

        a:hover {
            color: App.$Light-Purple;
        }
    }

    a {
        text-decoration: none;
        animation: .8s ease-in-out 0s infinite jump;
        
        .fa-hand-point-up {
            display: none;
            color: App.$Light-Purple;
            font-size: 6vmin;
            animation: .8s ease-in-out 0s infinite jump;
            padding: .2rem 1rem;

            @media (min-width: 1024px) {
                display:  inline;
                font-size: 3.5vmin;
            }
        }

        @keyframes jump {
            50%  {transform: translateY(5px);}
        }
    }
}
