// @import url("https://fonts.googleapis.com/css2?family=Alumni+Sans+Pinstripe&family=Bebas+Neue&family=Montserrat&display=swap");

/* Color Palette*/
$White: rgb(229, 229, 229);
$Light-Gray: rgb(153, 153, 153);
$Light-Purple: rgb(144, 84, 244);
$Medium-Purple: rgb(87, 39, 163);
$Dark-Purple: rgba(26, 0, 68, 1);
$Dark-Gray: rgb(20, 20, 20);

$Bebas: "Bebas Neue", "Courier New", monospace;
$Alumni: "Alumni Sans Pinstripe", "Courier New", monospace;
$Montserrat: "Montserrat", "Courier New", monospace;

body {
  height: 100%;
  background: rgb(3, 3, 3);
  font-size: 16px;
  font-family: $Montserrat, "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Courier New", monospace;
}

.App {
  text-align: center;
}

html {
  overflow-y: scroll;
}

html::-webkit-scrollbar {
  width: 10px;
  transition: all 0.3s ease-in-out;
}
html::-webkit-scrollbar-track {
  background: rgb(25, 25, 25);
}

html::-webkit-scrollbar-thumb {
  background: rgb(47, 21, 88);
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}
html::-webkit-scrollbar-thumb:hover {
  background: $Medium-Purple;
}

.header,
section {
  padding: 20px;
  text-align: left;
}

/*Mixins */
@mixin sectionPadding {
  padding: 50px 0px 0px 0px;

  @media ((min-width: 768px) and (max-width: 1023px)) {
    padding: 100px 0px 0px 0px;
  }

  @media (min-width: 1024px) {
    padding: 100px 250px 0px 250px;
  }
}

@mixin roundedBorders {
  border-radius: 5px;
}

@mixin p(
  $color: $Light-Gray,
  $font: $Montserrat,
  $size-s: 3vmin,
  $size-m: 2vmin,
  $size-l: 1.7vmin
) {
  color: $color;
  font-size: $size-s;
  font-family: $font;

  @media (min-width: 1024px) {
    font-size: $size-l;
  }
}

@mixin flex($direction: row, $justify: center, $align: left) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

.icon {
  margin: 1vmin 0vmin 1vmin 2vmin;
  color: $Light-Purple;
  font-size: 6vmin;

  @media (min-width: 1024px) {
    font-size: 3vmin;
  }
}

.icon:hover {
  transform: translateY(-3px);
  transition: all 0.3s ease-in-out;
}

/*Buttons*/
button {
  margin: 3vmin 0 0 0;
  padding: 15px 30px 15px 30px;
  border: 2px solid $Light-Purple;
  border-radius: 5px;
  background-color: transparent;
  color: $Light-Purple;
  font-family: $Montserrat;
  font-size: 4vmin;
  cursor: pointer;

  @media (min-width: 1024px) {
    font-size: 2.5vmin;
  }
}

button:hover {
  background-color: rgba(173, 122, 255, 20%);
  transition: all 0.2s ease-in-out;
}

/*Hidden class for animations*/
.hidden {
  display: none;
}

/*Filler class for flexbox stuff*/
.filler {
  flex: 1 1 auto;
}

h2 {
  display: flex;
  flex-direction: row;
  font-size: 10vmin;
  margin: 5vmin;
  color: $White;
  font-family: $Bebas;

  @media (min-width: 1024px) {
    font-size: 7vmin;
  }
}

h2:before,
h2:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
  color: $White;
}

h2:before {
  margin-right: 10px;
}

h2:after {
  margin-left: 10px;
}
