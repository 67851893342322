@use '../../App';

.invisible {
    top: -100px;
    transition: .5s, top .5s;
}

.burger-icon {
    position: fixed;
    width: 35px;
    height: 30px;
    right: 20px;
    top: 20px;
    transition: .5s, top .5s;
    display: flex;
    flex-direction: column;
    z-index: 90;
    @media ((min-width: 768px) and (max-width: 1023px)) {
        width: 50px;
        height: 40px;
    }
  
    @media (min-width: 1024px) {
          display: none;
    }

    .burger-line {
        background: App.$White;
        width: 100%;
        height: 3px;
        border-radius: 10px;
        margin: auto;
        transition: .5s, top .5s;
    }
}

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    z-index: 99;
    margin: 10px;
    transform: scale(2.0);
    
    @media ((min-width: 768px) and (max-width: 1023px)) {
      z-index: 99;
      margin: 30px;
      transform: scale(3.0);
    }
    .fa-xmark {
      color: App.$White;
    }
  }

.bm-menu-wrap {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 90;
    transform: translateX(100%);
    transition: all 0.5s ease-out;
}
  
/* General sidebar styles */
.bm-menu {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background: radial-gradient(
      100% 100% at bottom left, 
      rgba(26, 0, 68, 1.0) 0%,
      rgba(10,10,10,1.0) 100%
    );
    padding: 1.5em .5em 0;
    font-size: 1.15em;
    box-shadow: -5px 0px 30px black;
    transform: translateX(100%);
    transition: all 0.5s ease-out;
}

.bm-item-list {
    padding: 10px;
    @include App.flex($direction: column, $justify: center);
}

.bm-item {
    margin-bottom: 20px;
    a {
        color: App.$White; 
        text-align: left;
        text-decoration: none;
        font-family: App.$Montserrat;
        font-size: 8vmin;

        @media (min-width: 1024px) {
            font-size: 4vmin;
        }
    }
    a:hover {
        color: App.$Light-Purple;
    }

  }


/* Styling of overlay */
/*.bm-overlay {
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
}*/

