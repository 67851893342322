@use '../../App';

/*Footer*/

.footer {
  @include App.sectionPadding;
  background: radial-gradient(
        500px 500px at top right, 
         rgba(26, 0, 68, 1.0) 0%,
         rgba(20,20,20,0) 100%
    );
}

.footer p{
  @include App.p($font: App.$Montserrat, $size-s: 4vmin, $size-l: 2vmin);
  padding-bottom: 3vmin;
}