.canvas-container {
  height: 100vh;
  width: 100vw;
}

.hidden-canvas {
  height: 50vh;

  @media screen and (min-width: 1024px) {
    height: 50vh;
  }
}
