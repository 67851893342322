@use '../../App';

#contact-section {
    @include App.sectionPadding;
    text-align: center;
    background: radial-gradient(
        500px 500px at top left,
        rgba(26, 0, 68, 1.0) 0%, 
        rgba(20,20,20,0) 100%
    ), radial-gradient(
        500px 500px at bottom right, 
         rgba(26, 0, 68, 1.0) 0%,
         rgba(20,20,20,0) 100%
    );

    h5 {
        color: App.$White;
        font-family: App.$Montserrat;
        font-size: 5vmin;

        @media (min-width: 1024px) {
          font-size: 5vmin;
          font-family: App.$Alumni;
        }
    }

    .container {
        margin: 8vmin 15vmin 0vmin 15vmin;
        @include App.flex($direction: column, $justify: space-between);

        @media (min-width: 1024px) {
            @include App.flex($direction: row, $justify: space-between);
        }

        .social-media {
            text-align: center;
            padding: 0 5vmin 0 5vmin;
            margin: 0 0 5vmin 0;

            @media (min-width: 1024px) {
                margin: 0;
            }

            p {
                @include App.p($size-s: 4vmin, $size-l: 3vmin);
            }

            .social-links-container{
                @include App.flex();
                .fa-linkedin-in {
                    margin: 2vmin 2vmin 2vmin 0;
                    font-size: 8vmin;

                    @media (min-width: 1024px) {
                        font-size: 5vmin;
                    }
                }
                  
                .fa-github {
                    margin: 2vmin;
                    font-size: 8vmin;

                    @media (min-width: 1024px) {
                        font-size: 5vmin;
                    }
                }
                  
                .fa-codepen {
                    margin: 2vmin;
                    font-size: 8vmin;

                    @media (min-width: 1024px) {
                        font-size: 5vmin;
                    }
                }
            }
        }

        .email {
            text-align: center;
            padding: 0 5vmin 0 5vmin;

            @media (min-width: 1024px) {
                margin: 0;
            }

            button {
                margin: 3vmin 0 0 0;

                a {
                    color: App.$Light-Purple;
                    font-family: App.$Montserrat;
                    text-decoration: none;
                    font-size: 4vmin;
                    
                    @media (min-width: 1024px) {
                      font-size: 2.5vmin;
                    }
                }
            }
        }
    }

}