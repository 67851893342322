@use "../../App";

#hero {
  @include App.flex($align: center);
  position: relative;
  background: radial-gradient(
    100% 100% at bottom right,
    rgba(26, 0, 68, 1) 0%,
    rgba(20, 20, 20, 0) 100%
  );

  .container {
    position: absolute;
    top: 15%;
    padding: 50px 0px 50px 0px;
    z-index: 5;

    @media (min-width: 1024px) {
      padding: 0px 100px 20px 100px;
      top: 10%;
    }
  }

  h1 {
    font-family: App.$Bebas;
    font-size: 16vmin;
    color: App.$White;
    letter-spacing: 0.4rem;

    @media (min-width: 1024px) {
      font-size: 25vmin;
    }
  }

  p {
    @include App.p(
      $font: App.$Bebas,
      $color: App.$Light-Purple,
      $size-s: 6vmin,
      $size-l: 5vmin
    );
    letter-spacing: 0.3rem;
  }
}
